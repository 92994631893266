import React, { ReactNode } from "react";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="fallback">
          <h1 className="txt-poster">Hoppla!</h1>
          <p className="txt-heavy">Tut uns leid, etwas funktioniert nicht.</p>
          <button
            onClick={() => location.reload()}
            className="button"
            aria-label="Seite neu laden"
            title="Seite neu laden"
            role="button"
          >
            Nochmals versuchen
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}
